import * as React from "react"
import { Link, graphql } from 'gatsby'
import { linkResolver } from '../../../utils/link_resolver'
import Seo from '../../../components/seo'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const MeasurementDogsPage = ({data}) => {
  const entry = data.prismicMeasurementDogsPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />

      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full block object-cover py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="leading-snug text-2xl text-white font-poppins font-semibold">
              <h2 className="mb-8">
                <span className="bg-white text-3xl text-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.tag1}</span>
              </h2>
              <p className="ml-3">{entry.data.heading}</p>
            </div>
            <Link to="/contact/" className="mt-8 inline-block bg-orange px-3 py-1 text-2xl text-white font-poppins font-medium">Contact us</Link>
          </div>
        </div>
      </div>

      <div className="w-full my-16">
        <div className="w-10/12 md:w-7/12 mx-auto max-w-screen-xl">
          <div className="font-poppins text-center max-w-none prose prose-h2:text-diamond prose-h2:text-2xl prose-h2:font-semibold leading-snug">
            <PrismicRichText linkResolver={linkResolver} field={entry.data.text.richText} />
          </div>
        </div>
      </div>

      {entry.data.strap_text.richText &&
      <div className="w-full mt-16 mb-8">
        <div className="w-10/12 md:w-7/12 mx-auto max-w-screen-xl">
          <div className="mb-6 font-poppins text-orange text-2xl font-semibold leading-snug">
            {entry.data.strap_heading}
          </div>
          <div className="font-poppins max-w-none prose prose-p:font-medium prose-h2:text-diamond prose-h2:text-2xl prose-h2:font-semibold leading-snug">
            <PrismicRichText linkResolver={linkResolver} field={entry.data.strap_text.richText} />
          </div>
        </div>
      </div>
      }

      {entry.data.strap_image.gatsbyImageData && 
      <div className="w-full mb-16">
        <div className="w-10/12 md:w-7/12 mx-auto max-w-screen-xl">
          <div>
            <GatsbyImage className="w-full h-full object-cover block" image={entry.data.strap_image.gatsbyImageData} alt={entry.data.strap_image.alt ?? ''} />
          </div>
        </div>
      </div>
      }

      <div className="w-full my-16">
        <div className="w-10/12 md:w-8/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between items-center gap-12">
            <div className="w-full md:w-3/12">
              <div>
                <GatsbyImage className="aspect-w-1 aspect-h-1 rounded-full w-full h-full object-cover block" image={entry.data.image1.gatsbyImageData} alt={entry.data.image1.alt ?? ''} />
              </div>
            </div>
            <div className="w-full md:w-9/12 pt-12 md:pt-0">
              <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
                <h2>{entry.data.heading1}</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.text1.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3 text-center">Dog collar &amp; harness size</th>
              <th className="w-9/12 text-white p-3 text-left">Suitable for the following dog breeds (examples and guidance only)</th>
            </tr>
            {entry.data.dog_breeds.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="text-center font-bold p-3 text-diamond">{entry.col1}</td>
                <td className="p-3 font-normal text-diamond-80">{entry.col2}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="text-center font-bold p-3 text-diamond">{entry.col1}</td>
                <td className="p-3 font-normal text-diamond-80">{entry.col2}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Dog collar size</th>
              <th className="w-3/12 text-white p-3">Neck size (A)</th>
              <th className="w-3/12 text-white p-3">Collar width<br />(single layer)</th>
              <th className="w-3/12 text-white p-3">Collar width<br />(double layer)</th>
            </tr>
            {entry.data.collar_size.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.collar_size_col1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col3}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col4}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.collar_size_col1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col3}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.collar_size_col4}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between items-center gap-12">
            <div className="w-full">
              <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
                <PrismicRichText linkResolver={linkResolver} field={entry.data.text4.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange text-white">
              <th className="w-3/12 p-1 sm:p-3">Collar style</th>
              <th className="w-3/12 p-1 sm:p-3">Best collar use</th>
              <th colspan="5" className="w-6/12 p-1 sm:p-3">Size availability by collar style</th>
            </tr>
            <tr className="bg-orange text-white">
              <td colspan="2"></td>
              <td className="w-1/12 border-t-2 border-l-2 border-r-2 p-1 sm:p-3 text-white">XS</td>
              <td className="w-1/12 border-t-2 border-r-2 p-1 sm:p-3 text-white">S</td>
              <td className="w-1/12 border-t-2 border-r-2 p-1 sm:p-3 text-white">M</td>
              <td className="w-1/12 border-t-2 border-r-2 p-1 sm:p-3 text-white">L</td>
              <td className="w-1/12 border-t-2 p-1 sm:p-3 text-white">XL</td>
            </tr>
            {entry.data.collar_style.map((entry, i) => (
              <>
              {i%2 ? 
                <tr className="border-b border-diamond-50">
                  <td className="font-bold p-1 sm:p-3 text-diamond">{entry.col_1}</td>
                  <td className="p-1 sm:p-3 font-medium text-diamond-80">{entry.col_2}</td>
                  <td className="p-1 sm:p-3 font-medium text-diamond-80">{entry.col_3}</td>
                  <td className="p-1 sm:p-3 font-medium text-diamond-80">{entry.col_4}</td>
                  <td className="p-1 sm:p-3 font-medium text-diamond-80">{entry.col_5}</td>
                  <td className="p-1 sm:p-3 font-medium text-diamond-80">{entry.col_6}</td>
                  <td className="p-1 sm:p-3 font-medium text-diamond-80">{entry.col_7}</td> 
                </tr>
                :
                <tr className="border-b border-diamond-50 bg-diamond-10">
                  <td className="font-bold p-1 sm:p-3 text-diamond">{entry.col_1}</td>
                  <td className="p-1 sm:p-3 font-medium text-diamond-80">{entry.col_2}</td>
                  <td className="border-l-2 border-white p-1 sm:p-3 font-medium text-diamond-80">{entry.col_3}</td>
                  <td className="border-l-2 border-white p-1 sm:p-3 font-medium text-diamond-80">{entry.col_4}</td>
                  <td className="border-l-2 border-white p-1 sm:p-3 font-medium text-diamond-80">{entry.col_5}</td>
                  <td className="border-l-2 border-white p-1 sm:p-3 font-medium text-diamond-80">{entry.col_6}</td>
                  <td className="border-l-2 border-white p-1 sm:p-3 font-medium text-diamond-80">{entry.col_7}</td> 
                </tr>
                }
                </>
              ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Dog harness size</th>
              <th className="w-3/12 text-white p-3">Strap width</th>
              <th className="w-3/12 text-white p-3">Girth circumference (B)</th>
            </tr>
            {entry.data.harness_size.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.harness_size1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.harness_size2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.harness_size3}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.harness_size1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.harness_size2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.harness_size3}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16">
        <div className="w-10/12 md:w-8/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between items-center gap-12">
            <div className="w-full md:w-3/12">
              <div>
                <GatsbyImage className="aspect-w-1 aspect-h-1 rounded-full w-full h-full object-cover block" image={entry.data.image3.gatsbyImageData} alt={entry.data.image3.alt ?? ''} />
              </div>
            </div>
            <div className="w-full md:w-9/12 pt-12 md:pt-0">
              <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
                <h2>{entry.data.heading3}</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.text3.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Dog lead length</th>
              <th className="w-3/12 text-white p-3">Slim lead width</th>
              <th className="w-3/12 text-white p-3">Wide lead width</th>
              <th className="w-3/12 text-white p-3">Best use</th>
            </tr>
            {entry.data.lead_length.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.lead_length1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length3}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length4}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.lead_length1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length3}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.lead_length4}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16">
        <div className="w-10/12 md:w-8/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between items-center gap-12">
            <div className="w-full md:w-3/12">
              <div>
                <GatsbyImage className="aspect-w-1 aspect-h-1 rounded-full w-full h-full object-cover block" image={entry.data.image2.gatsbyImageData} alt={entry.data.image2.alt ?? ''} />
              </div>
            </div>
            <div className="w-full md:w-9/12 pt-12 md:pt-0">
              <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:text-2xl prose-h2:font-semibold prose-p:font-medium leading-snug">
                <h2>{entry.data.heading2}</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.text2.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Feeding mat size</th>
              <th className="w-3/12 text-white p-3">Dog size</th>
              <th className="w-3/12 text-white p-3">Whole mat size</th>
              <th className="w-3/12 text-white p-3">Suits bowls</th>
            </tr>
            {entry.data.feeding_mat_size.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.feeding_mat_size1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size3}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size4}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.feeding_mat_size1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size3}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_mat_size4}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full my-16 font-poppins font-semibold">
        <div className="w-11/12 md:w-7/12 mx-auto max-w-screen-xl">
          <table className="w-full text-center text-sm md:text-base">
            <tr className="bg-orange border-b border-t border-diamond-50">
              <th className="w-3/12 text-white p-3">Feeding bowl volume</th>
              <th className="w-3/12 text-white p-3">Dog size</th>
              <th className="w-3/12 text-white p-3">Bowl diameter</th>
            </tr>
            {entry.data.feeding_bowl_volume.map((entry, i) => (
              <>
              {i%2 ? 
              <tr className="border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.feeding_bowl_volume1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_bowl_volume2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_bowl_volume3}</td>
              </tr>
              : 
              <tr className="bg-diamond-10 border-b border-diamond-50">
                <td className="font-bold p-3 text-diamond">{entry.feeding_bowl_volume1}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_bowl_volume2}</td>
                <td className="p-3 font-medium text-diamond-80">{entry.feeding_bowl_volume3}</td>
              </tr>
              }
              </>
            ))}
          </table>
        </div>
      </div>

      <div className="w-full relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="rounded-tl-[133px] w-full h-full block object-cover" image={entry.data.review_image.gatsbyImageData} alt={entry.data.review_image.alt ?? ''} />
        </div>
        <div className="absolute inset-0">
          <div className="flex items-end h-full">
            <div className="w-8/12">
              &nbsp;
            </div>
            <div className="w-3/12 h-full">
              <div className="w-full h-full block bg-gradient-to-l from-black to-transparent">
                &nbsp;
              </div>
            </div>
            <div className="w-3/12 h-full bg-black">
              &nbsp;
            </div>
          </div>
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between relative">
            <div className="w-full md:w-6/12">
              &nbsp;
            </div>
            <div className="w-full md:w-6/12 pt-12 pb-12 md:pt-72 md:pb-24 text-right">
              <div className="font-poppins leading-tight text-xl md:text-2xl font-semibold">
                <h2 className="text-white mb-6"><em>“{entry.data.review_quote}”</em></h2>
                <p className="text-orange">- {entry.data.review_author}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicMeasurementDogsPage {
    data {
      meta_description
      page_title
      tag1
      heading
      image {
        alt
        gatsbyImageData
      }
      text {
        richText
      }
      heading1
      text1 {
        richText
      }
      text4 {
        richText
      }
      image1 {
        alt
        gatsbyImageData
      }
      collar_size {
        collar_size_col1
        collar_size_col2
        collar_size_col3
        collar_size_col4
      }
      collar_style {
        col_1
        col_2
        col_3
        col_4
        col_5
        col_6
        col_7
      }
      lead_length {
        lead_length1
        lead_length2
        lead_length3
        lead_length4
      }
      harness_size {
        harness_size1
        harness_size2
        harness_size3
      }
      dog_breeds {
        col1
        col2
      }
      heading2
      text2 {
        richText
      }
      image2 {
        alt
        gatsbyImageData
      }
      feeding_mat_size {
        feeding_mat_size1
        feeding_mat_size2
        feeding_mat_size3
        feeding_mat_size4
      }
      feeding_bowl_volume {
        feeding_bowl_volume1
        feeding_bowl_volume2
        feeding_bowl_volume3
      }
      strap_heading
      strap_text {
        richText
      }
      strap_image {
        alt
        gatsbyImageData
      }
      heading3
      text3 {
        richText
      }
      image3 {
        alt
        gatsbyImageData
      }
      review_image {
        alt
        gatsbyImageData
      }
      review_quote
      review_author
    }
  }
}
`

export default MeasurementDogsPage
